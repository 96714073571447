import React from 'react'

export const BioId = (fill = '#2f2f2f') => (
  <svg
    fill={fill}
    viewBox="0 0 463 463"
    x="0px"
    y="0px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M431.5,400c-5.972,0-11.561,1.671-16.325,4.569L287.711,277.104c-0.222-0.222-0.457-0.421-0.698-0.609
		C297,264.198,303,248.539,303,231.5c0-17.017-5.985-32.656-15.948-44.946l86.888-86.888C383.268,106.77,394.897,111,407.5,111
		c30.603,0,55.5-24.897,55.5-55.5S438.103,0,407.5,0S352,24.897,352,55.5c0,12.603,4.23,24.232,11.334,33.559l-86.888,86.888
		C264.156,165.985,248.517,160,231.5,160s-32.656,5.985-44.946,15.948l-40.123-40.123c2.898-4.764,4.569-10.353,4.569-16.325
		c0-17.369-14.131-31.5-31.5-31.5S88,102.131,88,119.5s14.131,31.5,31.5,31.5c5.972,0,11.561-1.671,16.325-4.569l40.123,40.123
		C165.985,198.844,160,214.483,160,231.5c0,9.652,1.933,18.858,5.415,27.266l-95.404,51.677C62.761,301.63,51.776,296,39.5,296
		C17.72,296,0,313.72,0,335.5S17.72,375,39.5,375S79,357.28,79,335.5c0-4.137-0.642-8.126-1.827-11.876l95.408-51.679
		C185.485,290.684,207.077,303,231.5,303c2.74,0,5.44-0.17,8.101-0.472l9.943,44.743C239.159,352.426,232,363.14,232,375.5
		c0,17.369,14.131,31.5,31.5,31.5s31.5-14.131,31.5-31.5c0-17.139-13.761-31.115-30.813-31.483l-9.944-44.747
		c8.174-2.75,15.693-6.93,22.253-12.257c0.188,0.241,0.387,0.476,0.609,0.698l127.464,127.464
		C401.671,419.939,400,425.528,400,431.5c0,17.369,14.131,31.5,31.5,31.5s31.5-14.131,31.5-31.5S448.869,400,431.5,400z M39.5,360
		C25.991,360,15,349.009,15,335.5S25.991,311,39.5,311S64,321.991,64,335.5S53.009,360,39.5,360z M280,375.5
		c0,9.098-7.402,16.5-16.5,16.5s-16.5-7.402-16.5-16.5s7.402-16.5,16.5-16.5S280,366.402,280,375.5z M407.5,15
		c22.332,0,40.5,18.168,40.5,40.5S429.832,96,407.5,96S367,77.832,367,55.5S385.168,15,407.5,15z M103,119.5
		c0-9.098,7.402-16.5,16.5-16.5s16.5,7.402,16.5,16.5s-7.402,16.5-16.5,16.5S103,128.598,103,119.5z M231.5,288
		c-31.154,0-56.5-25.346-56.5-56.5s25.346-56.5,56.5-56.5s56.5,25.346,56.5,56.5S262.654,288,231.5,288z M431.5,448
		c-9.098,0-16.5-7.402-16.5-16.5s7.402-16.5,16.5-16.5s16.5,7.402,16.5,16.5S440.598,448,431.5,448z"
    />
    <path
      d="M231.5,192c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5c13.509,0,24.5,10.991,24.5,24.5c0,4.142,3.358,7.5,7.5,7.5
		s7.5-3.358,7.5-7.5C271,209.72,253.28,192,231.5,192z"
    />
    <path
      d="M407.5,47c4.687,0,8.5,3.813,8.5,8.5c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5c0-12.958-10.542-23.5-23.5-23.5
		c-4.142,0-7.5,3.358-7.5,7.5S403.358,47,407.5,47z"
    />
  </svg>
)

export const ErMito = (fill = '#2f2f2f') => (
  <svg
    fill={fill}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 470 470"
  >
    <g>
      <path d="M401.17,68.83C356.784,24.444,297.771,0,235,0C177.985,0,122.992,20.685,80.152,58.243   c-3.115,2.73-3.426,7.47-0.695,10.584s7.469,3.427,10.584,0.695C130.144,34.363,181.625,15,235,15c121.309,0,220,98.691,220,220   s-98.691,220-220,220S15,356.309,15,235c0-53.376,19.363-104.857,54.523-144.96c2.73-3.114,2.419-7.854-0.695-10.584   c-3.115-2.73-7.853-2.42-10.584,0.695C20.685,122.991,0,177.984,0,235c0,62.771,24.444,121.784,68.83,166.17S172.229,470,235,470   s121.784-24.444,166.17-68.83S470,297.771,470,235S445.556,113.216,401.17,68.83z" />
      <path d="m305.71,295.104c-2.928-2.928-7.677-2.929-10.606,0.001-3.491,3.491-7.271,6.652-11.279,9.469l-7.553-13.083c17.4-12.748 28.728-33.317 28.728-56.491 0-38.598-31.402-70-70-70-26.523,0-49.648,14.828-61.521,36.63l-13.974-5.738c3.972-7.646 9.123-14.728 15.391-20.995 2.929-2.93 2.929-7.678 0-10.607-2.929-2.928-7.678-2.928-10.606,0-18.888,18.887-29.29,43.999-29.29,70.71 0,4.143 3.358,7.5 7.5,7.5s7.5-3.357 7.5-7.5c0-8.685 1.304-17.168 3.801-25.236l13.982,5.741c-1.799,6.193-2.783,12.73-2.783,19.495 0,38.598 31.402,70 70,70 10.066,0 19.633-2.152 28.291-5.994l7.55,13.078c-4.447,2.073-9.072,3.768-13.83,5.04-4.002,1.07-6.379,5.181-5.31,9.183 0.896,3.354 3.928,5.565 7.241,5.565 0.641,0 1.293-0.083 1.941-0.256 8.439-2.255 16.53-5.62 24.064-9.971 0.019-0.011 0.039-0.017 0.057-0.028 0.038-0.022 0.073-0.049 0.11-0.071 7.486-4.343 14.42-9.659 20.596-15.836 2.93-2.929 2.93-7.678-5.68434e-14-10.606zm-125.71-60.104c0-30.327 24.673-55 55-55s55,24.673 55,55-24.673,55-55,55-55-24.673-55-55z" />
      <path d="m292.504,334.614c-17.396,10.066-37.28,15.386-57.504,15.386-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5 7.5,7.5c22.858,0 45.34-6.018 65.016-17.403 3.586-2.074 4.811-6.662 2.735-10.247-2.073-3.586-6.664-4.812-10.247-2.736z" />
      <path d="m169.984,122.403c-40.084,23.193-64.984,66.337-64.984,112.597 0,4.143 3.358,7.5 7.5,7.5s7.5-3.357 7.5-7.5c0-40.924 22.031-79.094 57.496-99.614 3.585-2.074 4.81-6.662 2.736-10.247-2.075-3.587-6.666-4.81-10.248-2.736z" />
      <path d="m121.863,121.862c-19.892,19.893-34.225,44.692-41.449,71.721-1.07,4.002 1.307,8.112 5.309,9.183 0.649,0.173 1.3,0.256 1.941,0.256 3.313,0 6.345-2.213 7.241-5.565 6.544-24.484 19.534-46.956 37.564-64.986 2.929-2.93 2.929-7.678 0-10.607-2.928-2.929-7.677-2.929-10.606-0.002z" />
      <path d="m379.966,369.329c3.14,2.698 7.875,2.342 10.576-0.801 31.894-37.117 49.458-84.539 49.458-133.528 0-113.037-91.963-205-205-205-113.038,0-205,91.963-205,205s91.962,205 205,205c48.989,0 96.411-17.564 133.528-49.458 3.142-2.699 3.5-7.435 0.801-10.576s-7.435-3.498-10.576-0.801c-34.399,29.557-78.349,45.835-123.753,45.835-104.766,0-190-85.233-190-190s85.234-190 190-190c104.767,0 190,85.233 190,190 0,45.404-16.278,89.354-45.835,123.753-2.699,3.142-2.341,7.877 0.801,10.576z" />
      <circle cx="204.5" cy="385.5" r="7.5" />
      <circle cx="187.5" cy="90" r="7.5" />
      <path d="m235.008,133.887c12.417,0 23.333-8.384 26.549-20.388 3.925-14.647-4.799-29.756-19.445-33.68-2.333-0.625-4.732-0.942-7.131-0.942-12.416,0-23.333,8.384-26.55,20.388-3.924,14.647 4.8,29.756 19.446,33.68 2.333,0.624 4.733,0.942 7.131,0.942zm-12.087-30.741c1.462-5.458 6.422-9.27 12.061-9.27 1.088,0 2.181,0.145 3.249,0.431 6.657,1.784 10.622,8.651 8.839,15.31-1.463,5.458-6.422,9.27-12.061,9.27-1.088,0-2.181-0.145-3.249-0.431-6.658-1.784-10.623-8.652-8.839-15.31z" />
      <path d="m134.411,356.143c0.223,0.214 0.457,0.421 0.712,0.61 0.027,0.02 0.056,0.033 0.083,0.052 1.907,1.564 3.838,3.1 5.82,4.576 4.783,3.562 10.457,5.443 16.407,5.443 8.657,0 16.909-4.142 22.074-11.078 9.055-12.163 6.527-29.425-5.635-38.48-8.039-5.985-15.151-13.098-21.138-21.138-5.166-6.937-13.417-11.077-22.073-11.077-5.951,0-11.625,1.883-16.407,5.444-12.163,9.056-14.689,26.318-5.634,38.48 7.495,10.066 16.159,19.164 25.791,27.168zm-11.199-53.618c2.174-1.619 4.75-2.475 7.448-2.475 3.999,0 7.659,1.836 10.042,5.036l-1.816,1.352c-3.322,2.474-4.01,7.173-1.537,10.495 1.472,1.977 3.732,3.021 6.021,3.021 1.557,0 3.128-0.483 4.473-1.484l2.598-1.934c4.466,4.63 9.294,8.907 14.471,12.762 5.528,4.116 6.677,11.963 2.562,17.491-2.383,3.2-6.043,5.036-10.042,5.036-2.699,0-5.275-0.855-7.449-2.474l1.613-2.167c2.473-3.322 1.785-8.021-1.538-10.494-3.323-2.476-8.021-1.786-10.495,1.537l-1.137,1.527c-6.514-6.007-12.468-12.605-17.778-19.736-4.113-5.529-2.964-13.375 2.564-17.493z" />
      <path d="m343.434,311.822c3.467,1.495 7.128,2.254 10.88,2.254 10.993,0 20.909-6.522 25.262-16.615 0.978-2.268 1.882-4.564 2.752-6.871 0.014-0.031 0.033-0.058 0.047-0.089 0.125-0.291 0.224-0.586 0.311-0.882 4.339-11.746 7.293-23.958 8.752-36.427 0.691-5.924-0.516-11.777-3.49-16.929-4.328-7.497-12.04-12.572-20.631-13.577-1.066-0.125-2.148-0.188-3.217-0.188-13.938,0-25.671,10.45-27.29,24.308-1.164,9.954-3.768,19.669-7.736,28.875-2.909,6.745-3.017,14.22-0.305,21.046 2.711,6.826 7.919,12.187 14.665,15.095zm20.665-74.324c0.488,0 0.985,0.028 1.476,0.086 3.963,0.464 7.383,2.716 9.382,6.18 1.35,2.336 1.896,4.995 1.582,7.687-1.034,8.831-2.891,17.523-5.528,25.981l-1.748-.754c-3.801-1.641-8.216,0.113-9.856,3.917-1.641,3.803 0.113,8.216 3.917,9.856l2.48,1.07c-1.98,4.59-6.489,7.555-11.489,7.555-1.697,0-3.36-0.346-4.94-1.027-3.065-1.322-5.433-3.759-6.666-6.861-1.232-3.104-1.184-6.501 0.139-9.567 2.556-5.928 4.599-12.045 6.151-18.287l3.215,.376c0.296,0.034 0.59,0.052 0.881,0.052 3.752,0 6.993-2.812 7.439-6.63 0.481-4.114-2.464-7.84-6.578-8.32l-2.247-.263c0.734-6.301 6.062-11.051 12.39-11.051z" />
      <path d="m339.34,184.949c5.951,0 11.625-1.883 16.407-5.444 5.892-4.387 9.722-10.806 10.786-18.073s-0.766-14.516-5.152-20.407c-7.496-10.067-16.16-19.164-25.792-27.168-0.223-0.214-0.457-0.42-0.711-0.61-0.034-0.026-0.071-0.046-0.106-0.071-1.9-1.557-3.823-3.087-5.797-4.557-4.783-3.561-10.456-5.443-16.407-5.443-8.657,0-16.908,4.142-22.073,11.078-9.056,12.162-6.528,29.425 5.635,38.48 8.039,5.985 15.15,13.098 21.138,21.138 5.165,6.937 13.417,11.077 22.072,11.077zm-34.253-44.246c-5.528-4.116-6.678-11.963-2.562-17.491 2.383-3.2 6.043-5.036 10.042-5.036 2.699,0 5.274,0.855 7.448,2.475 0.001,0-1.612,2.167-1.612,2.167-2.474,3.322-1.785,8.021 1.537,10.494 1.345,1.002 2.915,1.484 4.473,1.484 2.289,0 4.55-1.044 6.021-3.021l1.137-1.527c6.514,6.007 12.468,12.605 17.778,19.736 1.994,2.678 2.825,5.972 2.342,9.275s-2.225,6.221-4.904,8.216c-2.174,1.619-4.749,2.475-7.447,2.475-3.998,0-7.659-1.836-10.042-5.036l1.815-1.352c3.322-2.474 4.01-7.173 1.536-10.495-2.475-3.321-7.171-4.01-10.495-1.536l-2.597,1.934c-4.465-4.631-9.293-8.907-14.47-12.762z" />
    </g>
  </svg>
)

export const Germs = (fill = '#2f2f2f') => (
  <svg
    fill={fill}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M329.669,94.916c-31.353-31.353-73.038-48.619-117.377-48.619c-44.339,0-86.024,17.266-117.377,48.619
			C59.44,130.39,42.037,179.389,47.169,229.349c0.43,4.193,4.183,7.236,8.372,6.813c4.193-0.43,7.243-4.179,6.813-8.372
			c-4.661-45.371,11.143-89.868,43.355-122.081c28.47-28.47,66.322-44.149,106.584-44.149c40.262,0,78.115,15.679,106.584,44.149
			s44.149,66.322,44.149,106.584c0,40.262-15.679,78.115-44.149,106.584c-28.47,28.47-66.322,44.149-106.584,44.149
			s-78.115-15.679-106.584-44.149c-18.095-18.096-31.352-40.58-38.336-65.024c-1.159-4.053-5.382-6.401-9.435-5.242
			c-4.053,1.158-6.4,5.383-5.242,9.435c7.696,26.932,22.295,51.699,42.219,71.623c31.353,31.353,73.038,48.619,117.377,48.619
			c44.339,0,86.024-17.266,117.377-48.619c31.353-31.353,48.619-73.038,48.619-117.377S361.021,126.268,329.669,94.916z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M498.045,430.667L392.263,324.884c40.921-65.457,43.764-149.75,3.994-218.643c-2.107-3.651-6.776-4.901-10.425-2.794
			c-3.65,2.107-4.902,6.775-2.794,10.425c44.459,77.016,31.538,174.778-31.425,237.741c-37.214,37.214-86.692,57.708-139.32,57.708
			c-52.628,0-102.107-20.495-139.321-57.708c-37.214-37.213-57.708-86.692-57.708-139.32c0-52.628,20.494-102.106,57.708-139.32
			c37.214-37.214,86.692-57.709,139.321-57.709c52.628,0,102.106,20.495,139.32,57.708c5.513,5.514,10.741,11.391,15.534,17.467
			c2.61,3.309,7.409,3.875,10.719,1.265c3.309-2.611,3.875-7.41,1.265-10.719c-5.162-6.544-10.789-12.87-16.725-18.805
			C322.309,22.083,268.998,0,212.293,0C155.587,0,102.276,22.083,62.179,62.179C22.083,102.276,0,155.587,0,212.293
			c0,56.706,22.083,110.017,62.178,150.114s93.408,62.179,150.114,62.179c40.468,0,79.203-11.254,112.644-32.268l105.73,105.728
			c8.998,8.998,20.963,13.955,33.689,13.955c12.727,0,24.692-4.957,33.69-13.955c8.998-8.998,13.955-20.963,13.955-33.689
			C512,451.63,507.044,439.665,498.045,430.667z M487.252,487.252c-6.116,6.116-14.247,9.484-22.897,9.484
			c-8.649,0-16.781-3.368-22.897-9.484l-103.681-103.68c8.652-6.367,16.889-13.427,24.628-21.166
			c7.757-7.757,14.801-16.01,21.136-24.658l103.711,103.711C499.878,454.084,499.878,474.627,487.252,487.252z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M163.675,140.293c-3.223,1.932-4.521,5.987-3.131,9.46c1.255,2.975,4.286,4.899,7.525,4.694
			c3.013-0.19,5.689-2.196,6.704-5.042C177.128,142.793,169.682,136.692,163.675,140.293z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M243.928,122.137c-2.818,2.493-3.339,6.812-1.276,9.941c1.862,2.729,5.34,4.035,8.526,3.075
			c2.882-0.868,5.01-3.392,5.393-6.376C257.454,121.92,249.066,117.593,243.928,122.137z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M256.705,234.864c-3.647-5.672-12.508-3.681-13.829,2.737c-1.409,6.844,6.769,11.742,12.209,7.486
			C258.161,242.681,258.893,238.105,256.705,234.864z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M129.162,284.379c-1.821-2.764-5.398-4.031-8.559-3.062c-2.935,0.901-5.056,3.506-5.374,6.553
			c-0.316,3.043,1.319,6.034,3.998,7.473c3.031,1.628,6.876,0.968,9.209-1.558C130.808,291.217,131.076,287.287,129.162,284.379z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M229.934,300.671c-2.006-3.014-5.918-4.157-9.261-2.819c-2.874,1.186-4.797,4.125-4.716,7.239
			c0.08,3.128,2.133,5.932,5.065,6.992c2.928,1.058,6.317,0.162,8.344-2.197C231.576,307.315,231.811,303.495,229.934,300.671z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M338.561,184.666c-1.226-4.034-5.49-6.31-9.521-5.083l-12.431,3.778c-5.865-9.707-16.681-15.482-28.012-14.953
			l-3.778-12.432c-1.226-4.034-5.49-6.308-9.521-5.083c-4.033,1.226-6.308,5.489-5.083,9.521l3.775,12.422
			c-4.745,2.855-8.649,6.947-11.326,11.96c-2.675,5.013-3.902,10.534-3.634,16.066l-12.422,3.775
			c-4.034,1.224-6.309,5.487-5.083,9.52c1,3.293,4.028,5.415,7.299,5.415c0.736,0,1.483-0.107,2.222-0.332l12.43-3.778
			c5.619,9.298,15.781,14.989,26.583,14.989c0.473,0,0.949-0.031,1.425-0.052l3.783,12.448c1,3.293,4.028,5.415,7.299,5.415
			c0.736,0,1.483-0.107,2.222-0.332c4.033-1.226,6.308-5.489,5.083-9.521l-3.778-12.432c9.706-5.866,15.479-16.683,14.952-28.011
			l12.432-3.778C337.511,192.961,339.787,188.698,338.561,184.666z M294.629,214.502c-8.323,2.529-17.149-2.184-19.678-10.504
			c-1.225-4.031-0.807-8.297,1.177-12.014c1.984-3.717,5.297-6.438,9.328-7.663c1.523-0.463,3.063-0.683,4.578-0.683
			c6.763-0.002,13.033,4.388,15.099,11.186C307.663,203.145,302.95,211.972,294.629,214.502z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M205.735,159.2c0.18,0.012,0.358,0.019,0.536,0.019c3.974,0,7.327-3.079,7.605-7.104c0.292-4.205-2.881-7.85-7.085-8.142
			l-6.666-0.463c-0.667-11.961-8.305-22.879-20.023-27.307l-0.458-0.173c-8.355-3.159-17.679-2.571-25.577,1.609
			c-5.523,2.924-9.965,7.421-12.831,12.827l-5.1-1.225c-4.099-0.984-8.219,1.541-9.203,5.638c-0.984,4.099,1.541,8.219,5.638,9.203
			l4.298,1.032c-1.43,7.05-2.497,14.172-3.217,21.33l-8.093-0.749c-4.195-0.384-7.914,2.7-8.302,6.896
			c-0.388,4.198,2.7,7.914,6.896,8.302l8.484,0.785c-0.199,6.558-0.104,13.126,0.287,19.675l-11.862,1.696
			c-4.173,0.596-7.072,4.462-6.475,8.635c0.544,3.807,3.809,6.552,7.545,6.552c0.359,0,0.724-0.025,1.09-0.078l11.164-1.597
			c0.718,5.504,1.628,10.983,2.769,16.416l-5.453,2.644c-3.792,1.839-5.377,6.404-3.538,10.197c1.319,2.72,4.04,4.304,6.873,4.304
			c1.117,0,2.251-0.246,3.324-0.766l3.663-1.776c2.865,4.782,7.003,8.761,12.042,11.428c4.539,2.404,9.549,3.619,14.576,3.619
			c3.717,0,7.445-0.665,10.996-2.008l0.443-0.167c0.001,0,0.003-0.001,0.004-0.002c11.899-4.496,19.594-15.666,20.068-27.826
			l6.474-0.892c4.176-0.576,7.094-4.427,6.518-8.603c-0.576-4.175-4.429-7.094-8.603-6.518l-6.65,0.917
			c-1.555-8.091-2.47-16.305-2.717-24.542l5.152-0.576c4.188-0.468,7.204-4.243,6.736-8.433c-0.468-4.189-4.242-7.21-8.433-6.736
			l-3.142,0.351c0.44-6.356,1.27-12.681,2.491-18.932L205.735,159.2z M184.486,227.836c1.834,7.65-2.378,15.533-9.797,18.337
			l-0.443,0.167c-4.26,1.609-9.013,1.311-13.039-0.821c-4.015-2.126-6.808-5.711-7.861-10.095c-7.404-30.813-7.404-63.387,0-94.202
			c1.053-4.384,3.845-7.97,7.861-10.095c2.314-1.225,4.867-1.845,7.43-1.845c1.897,0,3.8,0.34,5.614,1.026l0.438,0.166
			c7.419,2.803,11.631,10.687,9.797,18.337C178.292,174.66,178.292,201.987,184.486,227.836z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M223.588,268.628c-20.002,0-36.275,16.273-36.275,36.275c0,20.002,16.273,36.275,36.275,36.275
			c20.002,0,36.275-16.273,36.275-36.275C259.863,284.901,243.59,268.628,223.588,268.628z M223.588,325.914
			c-11.585,0-21.011-9.426-21.011-21.011c0-11.585,9.426-21.011,21.011-21.011s21.011,9.426,21.011,21.011
			C244.599,316.488,235.173,325.914,223.588,325.914z"
        />
      </g>
    </g>
  </svg>
)

export const ImsAls = (fill = '#2f2f2f') => (
  <svg
    fill={fill}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <g>
          <circle cx="196.267" cy="127.998" r="8.533" />
          <circle cx="162.133" cy="102.398" r="8.533" />
          <circle cx="93.867" cy="196.264" r="8.533" />
          <circle cx="153.6" cy="170.664" r="8.533" />
          <path
            d="M98.192,86.602c-23.973,0.002-43.406,19.438-43.404,43.411s19.436,43.406,43.41,43.406
				c23.973-0.001,43.407-19.435,43.407-43.408C141.576,106.047,122.155,86.628,98.192,86.602z M98.192,156.352
				c-14.548-0.002-26.339-11.797-26.337-26.344s11.795-26.34,26.343-26.339c14.548,0.001,26.34,11.794,26.34,26.342
				C124.521,144.553,112.735,156.338,98.192,156.352z"
          />
          <path
            d="M371.204,121.489l-0.016-0.004c-63.138-2.642-156.558-18.204-156.558-43.221c0.982-6.115,4.03-11.708,8.636-15.847
				c13.552,9.826,29.949,14.944,46.684,14.572c4.93,0.043,9.853-0.366,14.708-1.221c4.595-0.943,7.567-5.419,6.653-10.021
				s-5.371-7.601-9.978-6.717c-17.039,2.867-34.523-1.06-48.7-10.938c5.263-14.97,20.07-24.408,35.863-22.858
				c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533c-20.881-1.216-40.467,10.167-49.747,28.912
				c-6.858-5.796-10.979-14.196-11.366-23.167c0-4.713-3.82-8.533-8.533-8.533s-8.533,3.82-8.533,8.533
				c0.42,14.221,6.937,27.574,17.89,36.653c-12.26,7.864-26.305,12.507-40.836,13.501c-42.342,0-53.658-54.671-54.117-57
				c-0.85-4.308-4.83-7.268-9.2-6.843c-4.37,0.425-7.705,4.097-7.708,8.488c0,12.438-15.392,23.154-33.217,35.558
				C35.004,63.848,0,88.214,0,136.531c0,48.321,35.004,72.658,63.133,92.208c14.444,10.044,27.141,18.988,31.491,28.633
				c-22.033,4.939-52.508,19.972-52.508,52.392c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533
				c0-22,22.66-31.3,36.389-34.926c-0.374,14.609,1.347,39.503,15.319,55.572c8.964,10.171,22.041,15.752,35.587,15.188
				c4.713,0,8.533-3.821,8.533-8.533c0-4.713-3.82-8.533-8.533-8.533c-8.582,0.514-16.932-2.905-22.688-9.292
				c-15.258-17.508-10.508-53.437-10.463-53.8c0.015-0.109-0.03-0.208-0.019-0.317c0.012-0.118,0.079-0.214,0.086-0.333
				c0.033-0.521,3.587-52.167,35.313-52.167c9.258,0.312,16.734,7.665,17.2,16.917c0.043,1.732,0.153,2.993,0.244,3.823
				c0.2,11.82-4.613,23.173-13.248,31.247c-3.705,2.913-4.347,8.278-1.433,11.983c2.913,3.705,8.278,4.347,11.983,1.433
				c11.622-10.287,18.68-24.771,19.621-40.263c12.018-8.907,28.28-9.493,40.908-1.475c2.614,1.596,5.882,1.669,8.565,0.192
				s4.367-4.279,4.415-7.341c0.048-3.062-1.549-5.915-4.184-7.475c-14.645-9.37-33.046-10.662-48.857-3.43
				c2.288-11.497,7.62-22.17,15.441-30.903c19.292-22.254,64.7-48.788,171.467-48.788c74.229,0,124.104,36.912,124.104,91.854
				c0,69.787-80.725,84.962-158.792,99.638c-73.493,13.815-149.457,28.122-149.798,91.952l-0.027,0.111
				c-0.011,0.325-0.045,0.6-0.058,0.921c-0.411,3.998-4.877,27.113-52.958,27.113c-41.421,0-60.054,31.079-60.054,53.15
				c0,4.713,3.821,8.533,8.533,8.533c4.713,0,8.533-3.82,8.533-8.533c0.969-18.37,15.129-33.32,33.42-35.283
				c-5.584,10.895-8.255,23.05-7.753,35.283c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533
				c0-33.483,16.65-36.954,17.671-37.133c0.245-0.033,0.436-0.179,0.673-0.232c9.83-1.12,19.364-4.071,28.108-8.699
				c-1.43,3.703-2.952,7.153-4.472,10.531c-5.767,10.974-8.902,23.139-9.158,35.533c0,4.713,3.82,8.533,8.533,8.533
				s8.533-3.82,8.533-8.533c0.268-9.98,2.891-19.755,7.654-28.529c3.375-7.135,6.174-14.527,8.371-22.108
				c8.765,15.46,13.523,32.868,13.841,50.637c0,4.713,3.821,8.533,8.533,8.533c4.713,0,8.533-3.82,8.533-8.533
				c-0.047-14.006-2.686-27.883-7.786-40.928c5.312,1.673,10.762,2.868,16.286,3.571c0.206,0.05,0.371,0.183,0.583,0.219
				c0.721,0.121,17.692,3.329,17.692,37.138c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533
				c0.502-12.233-2.168-24.388-7.752-35.283c18.292,1.961,32.455,16.912,33.423,35.283c0,4.713,3.82,8.533,8.533,8.533
				s8.533-3.82,8.533-8.533c0-22.071-18.633-53.15-60.054-53.15c-28.826,0-41.97-8.393-47.972-15.953
				c-2.306-3.625-4.008-7.601-5.039-11.772l-0.002-0.016c0.007-50.035,66.024-62.443,135.916-75.584
				C420.246,331.589,512,314.344,512,230.398C512,165.346,455.429,121.622,371.204,121.489z M186.004,176.689
				c-6.796,7.859-12.101,16.891-15.654,26.654c-6.017-5.158-13.718-7.928-21.642-7.787c-28.038,0-41.15,22.7-47.225,41.921
				c-8.739-8.536-18.324-16.158-28.609-22.75c-26.158-18.183-55.808-38.796-55.808-78.196c0-39.404,29.65-60.042,55.813-78.25
				c11.324-7.11,21.701-15.627,30.883-25.346c10.192,23.033,30.325,48.2,63.608,48.2c10.458-0.247,20.778-2.439,30.433-6.462
				c-0.16,1.19-0.241,2.39-0.242,3.592c0,27.267,42.013,42.308,85.208,50.567C238.308,137.39,205.904,153.385,186.004,176.689z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const RnaGranules = (fill = '#2f2f2f') => (
  <svg
    fill={fill}
    viewBox="0 0 504 504.31325"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m344.324219 142.699219c-23.179688 0-41.96875 18.789062-41.96875 41.96875 0 23.175781 18.789062 41.964843 41.96875 41.964843 23.175781 0 41.964843-18.789062 41.964843-41.964843-.027343-23.167969-18.800781-41.941407-41.964843-41.96875zm0 67.148437c-10.183594 0-19.367188-6.136718-23.265625-15.546875-3.894532-9.40625-1.742188-20.238281 5.460937-27.441406 7.199219-7.199219 18.03125-9.355469 27.441407-5.457031 9.40625 3.898437 15.542968 13.078125 15.542968 23.265625 0 13.90625-11.273437 25.179687-25.179687 25.179687zm0 0" />
    <path d="m134.488281 327.355469c-18.542969 0-33.574219 15.03125-33.574219 33.574219 0 18.542968 15.03125 33.574218 33.574219 33.574218s33.574219-15.03125 33.574219-33.574218c0-18.542969-15.03125-33.574219-33.574219-33.574219zm0 50.359375c-9.273437 0-16.789062-7.515625-16.789062-16.785156 0-9.273438 7.515625-16.789063 16.789062-16.789063 9.269531 0 16.785157 7.515625 16.785157 16.789063 0 9.269531-7.515626 16.785156-16.785157 16.785156zm0 0" />
    <path d="m319.144531 251.8125c-18.542969 0-33.574219 15.03125-33.574219 33.574219s15.03125 33.574219 33.574219 33.574219c18.539063 0 33.570313-15.03125 33.570313-33.574219s-15.03125-33.574219-33.570313-33.574219zm0 50.363281c-9.273437 0-16.789062-7.515625-16.789062-16.789062 0-9.269531 7.515625-16.785157 16.789062-16.785157 9.269531 0 16.785157 7.515626 16.785157 16.785157 0 9.273437-7.515626 16.789062-16.785157 16.789062zm0 0" />
    <path d="m223.625 294.402344c-1.015625.429687-1.953125 1.027344-2.769531 1.769531-1.535157 1.609375-2.402344 3.738281-2.433594 5.960937.011719 1.097657.242187 2.179688.671875 3.1875.40625 1.027344 1.003906 1.96875 1.761719 2.769532.820312.738281 1.753906 1.335937 2.769531 1.765625 1.011719.425781 2.09375.652343 3.191406.671875 2.21875-.039063 4.347656-.90625 5.957032-2.4375.757812-.800782 1.359374-1.742188 1.761718-2.769532.433594-1.007812.660156-2.089843.671875-3.1875-.03125-2.222656-.898437-4.351562-2.433593-5.960937-2.433594-2.339844-6.015626-3.035156-9.148438-1.769531zm0 0" />
    <path d="m206.839844 369.992188c-1.019532.425781-1.953125 1.023437-2.769532 1.765624-1.535156 1.609376-2.402343 3.734376-2.4375 5.957032.015626 1.097656.242188 2.179687.671876 3.191406.40625 1.027344 1.003906 1.964844 1.765624 2.769531.816407.738281 1.75 1.335938 2.769532 1.761719 1.007812.429688 2.089844.65625 3.1875.671875 2.222656-.03125 4.351562-.902344 5.960937-2.433594.757813-.804687 1.355469-1.742187 1.761719-2.769531.429688-1.011719.660156-2.09375.671875-3.191406-.03125-2.222656-.902344-4.347656-2.433594-5.957032-2.4375-2.339843-6.019531-3.03125-9.148437-1.765624zm0 0" />
    <path d="m257.199219 344.761719c-1.015625.429687-1.953125 1.027343-2.769531 1.773437-1.542969 1.617188-2.414063 3.761719-2.433594 6 .011718 1.097656.242187 2.179688.671875 3.1875.40625 1.027344 1.003906 1.96875 1.761719 2.773438.816406.734375 1.753906 1.332031 2.769531 1.761718 1.011719.425782 2.09375.652344 3.191406.671876 2.21875-.035157 4.347656-.90625 5.957031-2.433594.757813-.804688 1.359375-1.746094 1.761719-2.773438.429687-1.007812.660156-2.089844.671875-3.1875-.03125-2.222656-.898438-4.351562-2.433594-5.960937-2.425781-2.355469-6.007812-3.066407-9.148437-1.8125zm0 0" />
    <path d="m338.363281 106.691406c.816407.742188 1.753907 1.335938 2.769531 1.761719 1.011719.429687 2.09375.660156 3.191407.671875 2.222656-.03125 4.347656-.902344 5.957031-2.433594.761719-.804687 1.359375-1.742187 1.765625-2.769531.429687-1.007813.65625-2.09375.667969-3.191406-.03125-2.21875-.898438-4.347657-2.433594-5.957031-2.433594-2.339844-6.015625-3.03125-9.148438-1.761719-1.015624.425781-1.953124 1.019531-2.769531 1.761719-1.53125 1.609374-2.402343 3.738281-2.433593 5.957031.011718 1.097656.242187 2.183593.671874 3.191406.40625 1.027344 1.003907 1.964844 1.761719 2.769531zm0 0" />
    <path d="m399.886719 126.53125c-1.015625.429688-1.953125 1.03125-2.769531 1.773438-1.542969 1.617187-2.414063 3.765624-2.433594 6 .011718 1.097656.242187 2.179687.671875 3.191406.40625 1.027344 1.003906 1.964844 1.761719 2.769531.820312.738281 1.753906 1.332031 2.769531 1.761719 1.011719.425781 2.09375.65625 3.191406.671875 2.21875-.035157 4.347656-.90625 5.957031-2.433594.761719-.804687 1.359375-1.742187 1.765625-2.769531.429688-1.011719.65625-2.09375.671875-3.191406-.03125-2.222657-.902344-4.351563-2.4375-5.957032-2.421875-2.359375-6.007812-3.066406-9.148437-1.816406zm0 0" />
    <path d="m392.023438 3.367188c-10.15625-2.210938-20.519532-3.335938-30.914063-3.3554692-66.054687-.8007808-123.824219 44.3359372-139.027344 108.6250002-16.484375 71.289062-50.078125 113.128906-94.578125 117.820312-38.03125 3.789063-72.945312 22.707031-96.886718 52.5-23.0625 28.417969-33.726563 64.914063-29.585938 101.277344 8.71875 71.398437 69.722656 124.832031 141.648438 124.070313 5.148437-.007813 10.296874-.289063 15.417968-.839844 63.738282-6.714844 276.78125-50.613282 342.167969-333.429688 8.328125-35.683594 1.882813-73.214844-17.871094-104.078125-20.453125-32.191406-53.042969-54.761719-90.371093-62.589843zm91.890624 162.910156c-62.890624 271.996094-266.625 314.089844-327.605468 320.554687-4.535156.480469-9.09375.71875-13.65625.71875-63.378906.726563-117.175782-46.304687-124.917969-109.214843-3.609375-31.910157 5.765625-63.933594 26.019531-88.859376 21.140625-26.304687 51.972656-42.996093 85.554688-46.316406 52.171875-5.515625 90.941406-51.9375 109.164062-130.738281 13.464844-56.644531 64.417969-96.378906 122.636719-95.625 9.207031.007813 18.382813.992187 27.378906 2.9375 32.941407 6.902344 61.695313 26.816406 79.738281 55.230469 17.335938 27.074218 22.992188 60.003906 15.6875 91.3125zm0 0" />
    <path d="m383.144531 44.34375c-7.242187-1.566406-14.628906-2.359375-22.035156-2.367188-46.335937-.253906-86.855469 31.175782-98.128906 76.121094-28.117188 121.519532-94.199219 146.207032-131.078125 150.101563-26.902344 2.648437-51.609375 15.996093-68.566406 37.046875-16.015626 19.710937-23.429688 45.035156-20.574219 70.269531 6.335937 50.148437 49.339843 87.53125 99.882812 86.824219 3.683594-.003906 7.367188-.199219 11.03125-.589844 56.746094-5.976562 246.53125-45.367188 305.671875-301.148438 5.773438-24.75 1.292969-50.785156-12.421875-72.183593-14.449219-22.6875-37.449219-38.582031-63.78125-44.074219zm59.886719 112.472656c-56.648438 244.964844-237.125 282.546875-291.09375 288.230469-3.066406.328125-6.148438.492187-9.234375.496094-42.003906.71875-77.84375-30.242188-83.238281-71.90625-2.347656-20.804688 3.777344-41.675781 16.988281-57.914063 14.160156-17.535156 34.765625-28.640625 57.195313-30.832031 41.328124-4.363281 115.207031-31.347656 145.652343-163.015625 9.476563-37.371094 43.257813-63.429688 81.808594-63.109375 6.207031.007813 12.398437.671875 18.464844 1.988281 21.953125 4.550782 41.136719 17.785156 53.191406 36.6875 11.289063 17.601563 14.992187 39.015625 10.265625 59.382813zm0 0" />
  </svg>
)
